$(document).ready(function () {

  homeHeroSlider();
  homeWhyUsSlider();
  homeAdaptationPanels();
  easyCounter();
  homeTestimonialsSlider();
  homePartnersCarousel();
  parallax();
  aos();
  mobileMenu();

});

function homeHeroSlider() {

  if ($('.home-page .hero').length !== 0) {

    let homeHeroSliderInit = new Swiper ('.home-page .hero', {
      loop: true,
      speed: 600,
      effect: 'fade',
      autoplay: {
        delay: 6000,
      },
      navigation: {
        prevEl: '.hero__navigation-prev',
        nextEl: '.hero__navigation-next'
      },
      pagination: {
        el: '.hero__dots',
        type: 'bullets',
        clickable: true
      }
    });

  }

}

function homeWhyUsSlider() {

  if ($('.home-page .why-us').length !== 0) {

    let homeWhyUsSliderInit = new Swiper ('.home-page .why-us__slider', {
      loop: true,
      speed: 600,
      effect: 'fade',
      autoplay: {
        delay: 10000,
      },
      navigation: {
        prevEl: '.why-us__slider-navigation-prev',
        nextEl: '.why-us__slider-navigation-next'
      },
      pagination: {
        el: '.why-us__slider-dots',
        type: 'bullets',
        clickable: true
      }
    });

  }

}

function homeAdaptationPanels() {

  if($('.home-page .adaptation').length !== 0) {

    let hoverEl = '.adaptation__panels-item';
    let activeEl = '.adaptation__panels-item.active';
    let descInEl = '.adaptation__panels-item-desc';

    $(activeEl).find(descInEl).show();
    $('.adaptation__panels-bgs-inner').css('left', '-100%');

    $(hoverEl).mouseover(function() {
      $(activeEl).find(descInEl).stop(true,true).slideUp(400);
      $(activeEl).stop(true,true).removeClass('active');
      $(this).find(descInEl).stop(true,true).slideDown(400);
      $(this).stop(true,true).addClass('active');
      if ($('#tab-01').hasClass('active')) {
        $('.adaptation__panels-bgs-inner').css('left', '0%');
      } else if ($('#tab-02').hasClass('active')) {
        $('.adaptation__panels-bgs-inner').css('left', '-100%');
      } else if ($('#tab-03').hasClass('active')) {
        $('.adaptation__panels-bgs-inner').css('left', '-200%');
      }
    });

  }

}

function easyCounter() {

  if ($('.counter').length !== 0) {

    $('.counter').easyCounter({
  		'duration': 1500,
  		'delay': 350,
      'runonce': true,
      'kilosepa': ' ',
  		'attrmin': 'data-min',
  		'attrmax': 'data-max',
      'attrkilosepa': 'data-kilosepa'
  	});

  }

}

function homeTestimonialsSlider() {

  if ($('.home-page .testimonials').length !== 0) {

    let homeTestimonialsCarouselInit = new Swiper ('.home-page .testimonials__carousel', {
      loop: true,
      speed: 600,
      autoHeight: true
    });

    let homeTestimonialsSliderInit = new Swiper ('.home-page .testimonials__slider', {
      loop: true,
      speed: 600,
      effect: 'fade',
      navigation: {
        prevEl: '.testimonials__slider-navigation-prev',
        nextEl: '.testimonials__slider-navigation-next'
      }
    });

    homeTestimonialsCarouselInit.controller.control = homeTestimonialsSliderInit;
    homeTestimonialsSliderInit.controller.control = homeTestimonialsCarouselInit;

  }

}

function homePartnersCarousel() {

  if ($('.home-page .partners').length !== 0) {

    let homePartnersCarouselInit = new Swiper ('.home-page .partners__carousel', {
      loop: true,
      speed: 600,
      slidesPerView: 5,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
        1025: {
          slidesPerView: 4
        },
        769: {
          slidesPerView: 3
        },
        561: {
          slidesPerView: 2
        }
      }
    });

  }

}

function parallax() {

  if ($('.parallax').length !== 0) {

    $('.parallax').parallax({
      speed: 0.6,
      parallax: 'scroll'
    });

  }

}

function aos() {

  AOS.init({
    offset: 300,
    delay: 0,
    duration: 500,
    once: true,
    disable: 'mobile'
  });

}

function mobileMenu() {

  $('.header__trigger').click(function() {
    $(this).stop(true,true).toggleClass('visible');
    $('.header__wrapper').stop(true,true).toggleClass('visible');
  });

}
